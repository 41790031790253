/**
 * Fontface mixin
 * @param string $weight Font family
 * @param int    $weight Font weight
 * @param type   $style  Font Style
 * @return rule
 */
/**
 * PxGrotesk mixin
 * @param int  $weight Font weight
 * @param type $style  Font Style
 * @return rule
 */
/**
 * Input placeholder styles
 */
/**
 * Last child margin reset
 * @param property $property
 */
/**
 * Creates a set of options (extends the current selector)
 * @param list $combos List example $list : ("selector-added-class" : ( "property" value, "property" value ))
 */
/**
 * Padded content
 */
/**
 * Slick dots overrides
 * @param int  $size    Dots size
 * @param list $color   List containing initial, hover and selected colors
 * @param int  $spacing Space between dots
 */
/**
 * Slick arrows overrides
 * @param int  $size      Arrows size
 * @param list $color     List containing initial, hover and disabled colors
 * @param int  $translate Special adjustment measure (depending on column offset for the slick container)
 */
/**
 * Custom icons
 */
/**
 * Vertical align using position absolute
 */
/**
 * Gradients
 */
/**
 * Blog lists
 */
.blog-list .list-item {
  display: flex;
  height: 100%;
  vertical-align: top; }
  .blog-list .list-item > .row {
    height: 100%; }
    .blog-list .list-item > .row > div {
      background-color: white; }
      .blog-list .list-item > .row > div > .row {
        height: 100%;
        flex-direction: column;
        flex-wrap: nowrap; }
  .blog-list .list-item .image {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto; }
  .blog-list .list-item .info {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    padding: 0.4em 0; }
    .blog-list .list-item .info > .row {
      width: 100%; }
    .blog-list .list-item .info h4 {
      font-family: "PxGrotesk";
      font-weight: 500;
      font-style: normal;
      margin-bottom: 0.3em;
      font-size: 1.3em; }
      @media (min-width: 768px) {
        .blog-list .list-item .info h4 {
          font-size: 1.1em; } }
      @media (min-width: 992px) {
        .blog-list .list-item .info h4 {
          font-size: 1.15em; } }
      .blog-list .list-item .info h4 a {
        text-decoration: none;
        color: #363636; }
    .blog-list .list-item .info p {
      margin: 0;
      font-family: "PxGrotesk";
      font-weight: 300;
      font-style: normal;
      font-size: 1.1em;
      text-align: left !important;
      line-height: 1.4em;
      color: #363636; }
      @media (min-width: 768px) {
        .blog-list .list-item .info p {
          font-size: 0.95em; } }
      @media (min-width: 992px) {
        .blog-list .list-item .info p {
          font-size: 0.85em; } }
      .blog-list .list-item .info p:last-child {
        margin-bottom: 0.8em; }
      .blog-list .list-item .info p a {
        transition: color 0.25s ease;
        color: #ff5100;
        text-decoration: underline; }
        .blog-list .list-item .info p a:hover {
          color: #cc4100; }
    .blog-list .list-item .info ul {
      list-style: none;
      font-family: "PxGrotesk";
      font-weight: 300;
      font-style: normal;
      font-size: 0.9em; }
      @media (min-width: 768px) {
        .blog-list .list-item .info ul {
          font-size: 0.85em; } }
      @media (min-width: 992px) {
        .blog-list .list-item .info ul {
          font-size: 0.7em; } }
      .blog-list .list-item .info ul li {
        display: inline-block;
        margin-right: 1.2em; }
        .blog-list .list-item .info ul li:last-child {
          margin-right: 0; }
        .blog-list .list-item .info ul li i {
          margin-right: 0.2em;
          display: inline-block;
          font: normal normal normal 14px/1 'CustomIcons';
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          text-transform: none;
          font-size: 1.2em; }
          .blog-list .list-item .info ul li i.icon-date:before {
            content: "J"; }
          .blog-list .list-item .info ul li i.icon-author:before {
            content: "c"; }
    .blog-list .list-item .info [class^="col-"] {
      padding: 0.6em 0.3em; }
      .blog-list .list-item .info [class^="col-"]:last-child {
        border-top: 1px solid #dedddd; }

.blog-list .slick-slide > div {
  height: 100%; }
